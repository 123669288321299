<template>
    <div>
        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card" :class="{ 'is-fullwidth': isFullWidth }">
                <header class="modal-card-head" :class="theme">
                    <p class="modal-card-title">{{ title }}</p>
                    <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
                </header>

                <section class="modal-card-body">
                    <slot>Loading content...</slot>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" :class="[completeText ? 'is-delete' : theme]" @click="close">{{ submitText ? submitText : 'Close' }}</button>
                    <button v-if="showComplete" class="button" :class="theme" @click="submit">{{ completeText ? completeText : 'Finish' }}</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import { db } from '../main.js';
import NProgress from 'nprogress';

export default {
    props: {
        title: String,
        theme: String,
        submitText: String,
        fullWidth: Boolean,
        completeText: String,
        showComplete: Boolean
    },

    computed: {},

    data: function() {
        return {
            showModal: true,
            isFullWidth: this.fullWidth ? this.fullWidth : false
        };
    },

    methods: {
        close() {
            this.showModal = false;
            let result = {
                answer: 'Modal closed',
                modal: false
            };
            this.$emit('closed', result);
        },
        submit() {
            this.showModal = false;
            let result = {
                answer: 'Action submitted',
                modal: false
            };
            this.$emit('submit', result);
        }
    }
};
</script>

<style lang="scss" scoped>
.modal {
    .modal-card {
        &.is-fullwidth {
            max-width: 1200px;
        }
        .modal-card-head {
            &.is-danger {
                background: #f14668;
            }
            &.is-dark {
                background: #363636;
            }
            &.is-primary {
                background: #00d1b2;
            }
            &.is-warning {
                background: #fbd15d;
            }
            &.is-skip {
                background: #ff7878;
            }
            &.is-gold {
                background: #eebe26;
            }
            &.is-blue {
                background: #2c7dcc;
            }
            &.is-purple {
                background: purple;
            }
            &.is-green {
                background: mediumseagreen;
            }
            &.is-hotpink {
                background: #f947a7;
            }
        }
        .modal-card-foot {
            .button {
                &.is-danger {
                    background: #f14668;
                    border-color: #f14668;
                    color: #fff;
                }
                &.is-dark {
                    background: #363636;
                    border-color: #363636;
                    color: #fff;
                }
                &.is-primary {
                    background: #00d1b2;
                    border-color: #00d1b2;
                    color: #fff;
                }
                &.is-warning {
                    background: #fbd15d;
                    border-color: #fbd15d;
                    color: #fff;
                }
                &.is-skip {
                    background: #ff7878;
                    border-color: #ff7878;
                    color: #fff;
                }
                &.is-gold {
                    background: #eebe26;
                    border-color: #eebe26;
                    color: #fff;
                }
                &.is-blue {
                    background: #2c7dcc;
                    border-color: #2c7dcc;
                    color: #fff;
                }
                &.is-purple {
                    background: purple;
                    border-color: purple;
                    color: #fff;
                }
                &.is-green {
                    background: mediumseagreen;
                    border-color: mediumseagreen;
                    color: #fff;
                }
                &.is-hotpink {
                    background: #f947a7;
                    border-color: #f947a7;
                    color: #fff;
                }
            }
        }
    }
}
</style>
